// extracted by mini-css-extract-plugin
export var checkbox = "Checkbox__checkbox__AcYbR";
export var column = "Checkbox__column__C4DgP";
export var label = "Checkbox__label__CW5v_";
export var row = "Checkbox__row__q5H7g";
export var sizeLg = "Checkbox__sizeLg__kLPJ7";
export var sizeMd = "Checkbox__sizeMd__bkNsf";
export var sizeSm = "Checkbox__sizeSm__BqH4I";
export var textLabel = "Checkbox__textLabel__WqGaY";
export var visuallyDisabled = "Checkbox__visuallyDisabled__VjoZt";
export var withLabel = "Checkbox__withLabel__j21pR";
export var wrapper = "Checkbox__wrapper__goAqn";